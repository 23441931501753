@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-layout {
  background-color: rgb(250, 244, 252)
}



.main-body-layout {
  background-color: white;
  padding: 3rem;
  border-radius: 0.5rem;
  height: fit-content;
}

.sidebar {
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
}

.sidebar-big {
  width: 200px; 
  padding: 20px;
}

.sidebar-small {
  width: 80px; 
  padding: 20px;
}

.sidebar-icon {
  transition: all 0.3s ease;
}
.sidebar-icon:hover {
  background-color: #FAF4FC;
  border-radius: 1rem;
}
.sidebar-icon i {
  font-size: 24px; /* Small icon size */
}

.selected-sidebar {
  background-color: #FAEECD;
}

.menu-item {
  width: 219px;
  display: flex;
  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: all 0.2s ease-in-out;
  margin: 10px;
  border-radius: 6px;
  font-size: 12;
}

.sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
}

.main-logo {
  width: 101px;
}

.sidebar-big {
  width: 240px;
}

.sidebar-small {
  width: 80px;
}
